<template>
  <div class="msg container van-tab__panel" :style="{ backgroundImage: 'url(img/pro-bg.png)' }">
    <van-tabs v-model:active="active">
      <van-tab :title="$t('login.msg1')">
        <div class="con default shadow mt-10 border-r5" v-for="item in msgList.notice" :key="item.id">
          <div class="head">【{{ item.published_at }}】{{ item.subject }}</div>
          <div>{{ item.content }}</div>
        </div>
        <div v-if="!msgList.notice.length" class="con txt-center">
          {{$t('public.nodata')}}
        </div>
      </van-tab>
      <van-tab :title="$t('login.msg2')">
        <div class="con default shadow mt-10 border-r5" v-for="item in msgList.message" :key="item.id"
          @click="readMsg(item)">
          <div class="head">
            <van-badge dot color="#fa1919" v-if="item.read_status === '未读'" />【{{ item.read_status }}】{{ item.subject }}
          </div>
          <div> {{ item.content }} </div>
        </div>
         <div v-if="!msgList.message.length" class="con txt-center">
          {{$t('public.nodata')}}
        </div>
      </van-tab>
    </van-tabs>
      <van-loading v-if="loading" />
  </div>
</template>

<script setup>
import { reactive ,ref} from 'vue'
import { getNotice, getMessage, readMessage } from "@/api/user";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const loading=ref(true);
const msgList = reactive({
  notice: [],
  message: []
});
const getData = () => {
  getNotice().then(res => {
    msgList.notice = res.data;
  })
  getMessage().then(res => {
    msgList.message = res.data.list;
    loading.value=false
  })
}
getData();
// 消息已读
const readMsg = (item) => {
  readMessage({ message_ids: [item.id] }).then(() => {
    item.read_status = t('public.read')
  })
}
</script>

<style lang="less" scoped>
.msg {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;
  min-height: 300px;

  .head {
    line-height: 30px;
    font-size: 13px;
    border-bottom: 1px solid #59587a;
    margin-bottom: 5px;
  }
}
</style>
